import { Field, FieldProps, Form, Formik } from 'formik';
import { InputV3 } from '../../../../components/InputV3';
import { SelectV3 } from '../../../../components/SelectV3';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { BillingModeType, createWorkspace, Customer, defaultRequiredMessage, StaticEntity, Workspace } from '../../../../api';
import { useLists } from '../../../../hooks/useLists';
import { useNavigate } from 'react-router';
import { useState } from 'react';
import * as yup from 'yup';

interface Props {
  customer: Customer;
}

export const AddWorkspace = (props: Props) => {
  const [triedToSubmit, setTriedToSubmit] = useState(false);
  const navigate = useNavigate();
  const lists = useLists();

  const billingOptions = [
    {
      type: BillingModeType.Free,
      name: 'No billing (free)',
    },
    {
      type: BillingModeType.Standalone,
      name: 'Stand-alone billing',
    },
    {
      type: BillingModeType.Volume,
      name: 'Volume billing',
    },
  ];

  const renderError = (error?: string) => error && <div className='absolute -bottom-1 translate-y-full text-red-500 text-xs'>{error}</div>;

  return (
    <Formik
      validateOnBlur={triedToSubmit}
      validateOnChange={triedToSubmit}
      validationSchema={yup.object().shape({
        name: yup.string().nullable().required(defaultRequiredMessage),
        targetSkuCount: yup.number().nullable().required(defaultRequiredMessage),
        methodology: yup.object().nullable().required(defaultRequiredMessage),
        brand: yup.object().nullable().required(defaultRequiredMessage),
        trialDays: yup.number().nullable(),
        billingMode: yup.object().nullable().required(defaultRequiredMessage),
      })}
      initialValues={
        {
          customerSid: props.customer.customerSid,
          billingMode: null,
        } as unknown as Workspace
      }
      onSubmit={(values, { setSubmitting }) => {
        createWorkspace(values).call({
          ok: () => {
            navigate(-1);
            setSubmitting(false);
          },
          fail: () => {
            setSubmitting(false);
          },
        });
      }}
    >
      {(formik) => (
        <Form className='flex flex-col justify-between h-[calc(100vh-80px)] xl:-mx-[calc((100vw-theme(screens.xl))/2+theme(spacing.12))] bg-neutral-50'>
          <div>
            <div className='flex flex-col w-full items-center justify-center border-b border-zinc-200 bg-white'>
              <div className='flex items-center gap-x-4 py-6 w-full xl:w-[theme(screens.xl)] px-12 text-black'>
                <button
                  type='button'
                  onClick={() => navigate(-1)}
                  className='size-8 rounded-lg bg-slate-200 flex items-center justify-center'
                >
                  <FontAwesomeIcon className='text-lg' icon={regular('chevron-left')} />
                </button>
                <div className='flex items-center gap-x-2 text-lg font-semibold bg-white'>
                  {props.customer.companyName} Organisation <FontAwesomeIcon className='text-xs' icon={regular('chevron-right')} /> New
                  Workspace
                </div>
              </div>
            </div>
            <div className='flex flex-col h-full w-full items-center pt-10'>
              <div className='grid grid-cols-3 gap-6 w-full xl:w-[theme(screens.xl)] px-12 *:text-black *:flex *:flex-col *:gap-1.5'>
                <Field name='name'>
                  {(model: FieldProps<string>) => (
                    <div className='flex flex-col gap-1.5 relative'>
                      <div className='font-semibold text-sm'>Workspace name</div>
                      <InputV3 autoFocus model={model} />
                      {renderError(model.meta.error)}
                    </div>
                  )}
                </Field>
                <Field name='targetSkuCount'>
                  {(model: FieldProps<number>) => (
                    <div className='relative'>
                      <div className='font-semibold text-sm'>Target product count</div>
                      <InputV3 integer positive model={model} />
                      {renderError(model.meta.error)}
                    </div>
                  )}
                </Field>
                <Field name='methodology'>
                  {(model: FieldProps<StaticEntity>) => (
                    <div className='relative'>
                      <div className='font-semibold text-sm'>Methodology</div>
                      <SelectV3 model={model} options={lists.methodologies} />
                      {renderError(model.meta.error)}
                    </div>
                  )}
                </Field>
                <Field name='brand'>
                  {(model: FieldProps<StaticEntity>) => (
                    <div className='relative'>
                      <div className='font-semibold text-sm'>Brand</div>
                      <SelectV3 model={model} options={lists.brands} />
                      {renderError(model.meta.error)}
                    </div>
                  )}
                </Field>
                <Field name='trialDays'>
                  {(model: FieldProps<number>) => (
                    <div>
                      <div className='font-semibold text-sm'>Trial days</div>
                      <InputV3 integer positive model={model} placeholder='Leave empty if not needed…' />
                    </div>
                  )}
                </Field>
                <Field name='billingMode'>
                  {(model: FieldProps<{ type: BillingModeType; name: string }>) => (
                    <div className='relative'>
                      <div className='font-semibold text-sm'>Billing mode</div>
                      <SelectV3 model={model} options={billingOptions} />
                      {renderError(model.meta.error)}
                    </div>
                  )}
                </Field>
              </div>
            </div>
          </div>
          <div className='border-t py-4 flex items-center justify-center bg-white'>
            <div className='flex justify-end w-full xl:w-[theme(screens.xl)] px-12'>
              <button
                disabled={formik.isSubmitting}
                onClick={() => setTriedToSubmit(true)}
                className='rounded-full py-2 px-3 bg-brand text-white font-semibold text-sm'
              >
                Create workspace
              </button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};
