import { useState } from 'react';
import { Field, FieldProps, Form, Formik } from 'formik';
import { InputV3 } from '../../../components/InputV3';
import { SelectV3 } from '../../../components/SelectV3';
import { useLists } from '../../../hooks/useLists';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { createCustomer, CustomerForm, defaultRequiredMessage, StaticEntity } from '../../../api';
import { useNavigate } from 'react-router';
import * as yup from 'yup';
import cn from 'classnames';

interface NewCustomerProps {
  onDone: () => void;
}

export const AddCustomer = (props: NewCustomerProps) => {
  const lists = useLists();
  const navigate = useNavigate();
  const [triedToSubmit, setTriedToSubmit] = useState(false);

  const renderError = (error?: string) => error && <div className='absolute -bottom-1 translate-y-full text-red-500 text-xs'>{error}</div>;

  return (
    <Formik<CustomerForm>
      validateOnBlur={triedToSubmit}
      validateOnChange={triedToSubmit}
      validationSchema={yup.object().shape({
        companyName: yup.string().nullable().required(defaultRequiredMessage),
        methodology: yup.object().nullable().required(defaultRequiredMessage),
      })}
      initialValues={
        {
          addDemoProducts: false,
        } as CustomerForm
      }
      onSubmit={(values, { setSubmitting }) => {
        setSubmitting(true);

        createCustomer(values).call({
          ok: () => {
            props.onDone();
            setSubmitting(false);
          },
          fail: () => {
            setSubmitting(false);
          },
        });
      }}
    >
      {(formik) => (
        <Form className='flex flex-col justify-between h-[calc(100vh-80px)] xl:-mx-[calc((100vw-theme(screens.xl))/2+theme(spacing.12))] bg-neutral-50'>
          <div>
            <div className='flex flex-col w-full items-center justify-center border-b border-zinc-200 bg-white'>
              <div className='flex items-center gap-x-4 py-6 px-12 w-full max-w-[theme(screens.xl)] text-black'>
                <button
                  type='button'
                  onClick={() => navigate('/customers')}
                  className='size-8 rounded-lg bg-slate-200 flex items-center justify-center'
                >
                  <FontAwesomeIcon className='text-lg' icon={regular('chevron-left')} />
                </button>
                <div className='text-lg font-semibold bg-white'>New Organisation</div>
              </div>
            </div>
            <div className='flex flex-col items-center justify-center pt-10'>
              <div className='flex flex-col gap-y-4 w-full xl:w-[theme(screens.xl)] px-12'>
                <div className='flex gap-x-4'>
                  <Field name='companyName'>
                    {(model: FieldProps<string>) => (
                      <div className='flex flex-col gap-1.5 w-60 relative'>
                        <div className='font-semibold text-sm'>Organisation name</div>
                        <InputV3 autoFocus model={model} />
                        {renderError(model.meta.error)}
                      </div>
                    )}
                  </Field>
                  <Field name='methodology'>
                    {(model: FieldProps<StaticEntity>) => (
                      <div className='flex flex-col gap-1.5 w-60 relative'>
                        <div className='font-semibold text-sm'>Methodology</div>
                        <SelectV3 model={model} options={lists.methodologies} />
                        {renderError(model.meta.error)}
                      </div>
                    )}
                  </Field>
                </div>
                <Field name='addDemoProducts'>
                  {(model: FieldProps<boolean>) => (
                    <div className='flex flex-col gap-y-2 mt-4'>
                      <div className='text-sm font-semibold'>Include demo products</div>
                      <div className='flex gap-x-2'>
                        <div className='relative flex items-center gap-4 pl-1'>
                          <input
                            checked={model.field.value}
                            onChange={(event) => {
                              model.field.onChange(event);
                              if (event.target.value) {
                                formik.setFieldValue('addDemoProducts', !model.field.value);
                              }
                            }}
                            className='opacity-0 absolute h-3 w-6 aspect-square z-10 hover:cursor-pointer disabled:opacity-0 disabled:cursor-not-allowed'
                            type='checkbox'
                          />
                          <div
                            className={cn(
                              'border border-white px-0.5 outline flex items-center rounded-full relative h-3 w-6',
                              model.field.value ? 'bg-brand' : 'bg-lightBg',
                            )}
                          >
                            <div
                              className={cn('absolute rounded-full h-2.5 aspect-square transition-[left] duration-25', {
                                'left-3 bg-white': model.field.value,
                                'left-px bg-brand': !model.field.value,
                              })}
                            />
                          </div>
                        </div>
                        <div className='text-sm font-semibold'>{model.field.value ? 'Yes' : 'No'}</div>
                      </div>
                    </div>
                  )}
                </Field>
              </div>
            </div>
          </div>
          <div className='border-t py-4 flex items-center justify-center bg-white'>
            <div className='flex justify-end w-ful w-full xl:w-[theme(screens.xl)] px-12'>
              <button
                disabled={formik.isSubmitting}
                onClick={() => setTriedToSubmit(true)}
                className='rounded-full py-2 px-3 bg-brand text-white font-semibold text-sm'
              >
                Create organisation
              </button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};
