import { useId } from '@floating-ui/react-dom-interactions';
import { light, regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cn from 'classnames';
import { Field, FieldArrayRenderProps, FieldProps, Formik, FormikContextType, useFormikContext } from 'formik';
import cloneDeep from 'lodash/cloneDeep';
import { PropsWithChildren, RefObject, forwardRef, useImperativeHandle, useRef, useState } from 'react';
import { OptionProps, components } from 'react-select';
import ReactSelectAsync from 'react-select/async';
import * as yup from 'yup';
import {
  Amount,
  Entity,
  IngredientNode,
  IngredientSupplierNode,
  IngredientType,
  IngredientV3,
  NodeType,
  ProductState,
  ProductV3,
  Supplier,
  SupplierService,
  getSuppliers,
  searchIngredientsV3,
} from '../../../../api';
import { InputV3 } from '../../../../components/InputV3';
import { ModalForm, ModalFormSaveCallback } from '../../../../components/ModalForm';
import { SelectFooterAddButton } from '../../../../components/SelectFooterAddButton';
import { Components, SelectV3 } from '../../../../components/SelectV3';
import { TooltipV3 } from '../../../../components/TooltipV3';
import { UnitInputV3 } from '../../../../components/UnitInputV3';
import { useControlEvents } from '../../../../hooks/useControlEvents';
import { useEffectOnNextRenders } from '../../../../hooks/useEffectOnNextRenders';
import { NewSupplierForm } from '../../Manage/Suppliers/NewSupplierForm';
import { DefaultBadge, renderBadges } from './Badges';
import { ExtractedData } from './ExtractedData';
import { LocationSelect } from './LocationSelect';
import { ModalHeaderRightBar } from './ModalHeaderRightBar';
import { TaggableField, TaggableFieldsContainer, TouchedAwareFieldArray } from './TaggableFields';
import {
  StepInputAmountSideEffect,
  UpdateSideEffects,
  adjustSupplierSplits,
  commentSchema,
  getProductionFacilities,
  is100Percent,
  newNodeId,
  roundAmount,
  shouldAutoAdjustSplit,
} from './dataModel';

const toSupplierOption = (supplier: Supplier, config?: { noId?: boolean }): SupplierNode => ({
  id: config?.noId ? '' : newNodeId(),
  type: NodeType.IngredientSupplier,
  displayName: supplier.name,
  flagged: false,
  edges: new Array<string>(),
  supplier,
  location: null as any as Entity,
  splitPercent: null as any as number,
  autoAdjustSplit: true,
});

interface SupplierNode extends IngredientSupplierNode {
  autoAdjustSplit: boolean;
}

type Props = PropsWithChildren<{
  data?: IngredientNode;
  onSave: ModalFormSaveCallback<IngredientNode, { sideEffects: UpdateSideEffects }>;
  onOpenChange?: (open: boolean) => void;
}>;

export const IngredientDetails = (props: Props) => {
  const formRef = useRef<HTMLDivElement>(null);
  const formik = useFormikContext<ProductV3>();
  const bodyRef = useRef<BodyApi>(null);

  return (
    <ModalForm
      onOpenChange={props.onOpenChange}
      formRef={formRef}
      title={props.data ? `${props.data.amount.value}${props.data.amount.unit.name} of ${props.data.displayName}` : 'New ingredient'}
      body={<Body ref={bodyRef} productFormik={formik} formRef={formRef} edit={!!props.data} />}
      headerRight={<ModalHeaderRightBar />}
      instructions={
        <div className='flex flex-col gap-4 p-2'>
          <div>
            This is where you add a new ingredient used in making your product. Remember, this is the ingredient in the state in which it
            gets to your production facility. Start typing and select from the list of options.
          </div>
          <div>Specify the amount of this ingredient you are procuring and which supplier(s) you’re getting it from.</div>
          <div>
            We know that you can’t always get an ingredient from the same supplier year round so you can specify what we call{' '}
            <span className='font-semibold'>the split</span>, or the percentage of time you got the ingredient from one supplier versus
            another when looking 3 years back.
          </div>
          <div>
            On the other hand, if you always get a different amount of the same ingredient from different suppliers, just add the ingredient
            again into the graph, as many times as you need.
          </div>
        </div>
      }
      emptyData={{
        id: newNodeId(),
        displayName: '',
        type: NodeType.Ingredient,
        nodes: new Array<IngredientSupplierNode>(),
        edges: new Array<string>(),
        flagged: false,
        // needs to be null not undefined, otherwise .shape() validation fails
        ingredient: null as any as IngredientV3,
        amount: undefined as any as Amount,
        localSupply: false,
      }}
      data={
        props.data
          ? {
              ...props.data,
              nodes: props.data.nodes.map(
                (node) =>
                  ({
                    ...node,
                    autoAdjustSplit: shouldAutoAdjustSplit(props.data!, node, formik, 'nodes', 'splitPercent'),
                  } as SupplierNode),
              ),
            }
          : undefined
      }
      metadata={formik.values.metadata}
      validationSchema={yup.object().shape({
        ingredient: yup.object().shape(commentSchema()).required(),
        amount: yup.object().shape({
          value: yup.number().positive().required(),
        }),
        nodes: yup
          .array()
          .min(1)
          .of(
            yup.object().shape({
              location: yup.object().required(),
              splitPercent: yup.number().positive().max(100).required(),
            }),
          )
          .test('', 'splitsNot100', function () {
            const parent = this.parent as IngredientNode;
            return (
              parent.nodes.length === 0 ||
              (parent.nodes.every(({ splitPercent }) => typeof splitPercent === 'number') &&
                is100Percent(parent.nodes.map(({ splitPercent }) => splitPercent)))
            );
          }),
      })}
      getCustomErrors={(errors) => [
        { message: 'The suppliers split must add up to 100%.', expected: 'splitsNot100', actual: errors.nodes },
      ]}
      entityName='ingredient'
      saveLabel={formik.values.state === ProductState.Complete ? 'Confirm changes' : undefined}
      onSave={({ values, ...rest }) => {
        props.onSave({
          values: values as IngredientNode,
          sideEffects: { stepInputAmounts: bodyRef.current!.getSideEffects() },
          ...rest,
        });
      }}
    >
      {props.children}
    </ModalForm>
  );
};

interface BodyProps {
  productFormik: FormikContextType<ProductV3>;
  formRef: RefObject<HTMLDivElement>;
  edit: boolean;
}

interface BodyApi {
  getSideEffects: () => StepInputAmountSideEffect[];
}

const Body = forwardRef<BodyApi, BodyProps>((props, ref) => {
  const formik = useFormikContext<IngredientNode>();
  const originalAmountValue = useRef(formik.values.amount?.value);
  const updateStepInputCheckboxId = useId();
  const [updateStepInput, setUpdateStepInput] = useState(true);
  const [showSupplierForm, setShowSupplierForm] = useState(false);
  const [newSupplierName, setNewSupplierName] = useState('');

  const getSingleStepInputUsingIngredient = () => {
    const inputs = getProductionFacilities(props.productFormik)
      .flatMap(({ steps }) => steps)
      .flatMap((step) => step.inputs.map((input) => ({ step, input })))
      .filter(({ input: { id } }) => id === formik.values.id);
    return inputs.length === 1 ? inputs[0] : undefined;
  };

  const canUpdateStepInput =
    props.edit &&
    typeof originalAmountValue.current === 'number' &&
    typeof formik.values.amount?.value === 'number' &&
    formik.values.amount.unit &&
    originalAmountValue.current !== formik.values.amount?.value &&
    getSingleStepInputUsingIngredient();

  useImperativeHandle(ref, () => ({
    getSideEffects: () => {
      if (canUpdateStepInput && updateStepInput) {
        const { step, input } = getSingleStepInputUsingIngredient()!;
        return [{ stepId: step.id, inputId: input.id, value: formik.values.amount.value }];
      }

      return [];
    },
  }));

  useEffectOnNextRenders(() => {
    formik.setValues((values) => {
      const newValues = cloneDeep(values);
      newValues.displayName = '';
      delete newValues.index;

      if (newValues.ingredient?.unit) {
        newValues.amount = newValues.amount ?? {};
        newValues.amount.unit = newValues.ingredient.unit;
      }

      return newValues;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.ingredient?.id]);

  useEffectOnNextRenders(() => {
    if ((formik.values.nodes as SupplierNode[]).some(({ autoAdjustSplit }) => autoAdjustSplit)) {
      adjustSupplierSplits(formik);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.nodes.length]);

  useEffectOnNextRenders(() => {
    if (formik.values.ingredient?.restrictedSuppliers) {
      formik.setFieldValue(
        'nodes',
        formik.values.ingredient.suppliers?.map(({ supplier, location, splitPercent }) => ({
          ...toSupplierOption(supplier),
          location,
          splitPercent,
        })),
      );
    }
  }, [formik.values.ingredient?.id]);

  return (
    <TaggableFieldsContainer pathPrefix='nodes'>
      <div className='grid grid-cols-2 gap-4'>
        <ExtractedData {...props} />
        <div className='flex flex-col gap-1'>
          <div className='pl-1.5'>Ingredient</div>
          <div>
            <TaggableField name='ingredient'>
              {(model: FieldProps<IngredientV3>) => <IngredientSelect model={model} {...props} />}
            </TaggableField>
          </div>
        </div>
        <div className='flex flex-col gap-1'>
          <div className='px-1.5 flex items-center justify-between'>
            <div>Amount</div>
            <AmountFromPercentOfNetWidget {...props} />
          </div>
          <div>
            <TaggableField name='amount.value'>
              {(model: FieldProps<number>) => (
                <UnitInputV3
                  model={model}
                  unit={{
                    options: formik.values.amount?.unit ? [formik.values.amount.unit] : [],
                  }}
                />
              )}
            </TaggableField>
          </div>
        </div>
        {formik.values.ingredient?.suppliers && formik.values.ingredient?.type === IngredientType.IntermediateProduct && (
          <div className='flex flex-col gap-y-2 col-span-2 p-2.5 bg-violet-100 rounded-lg border border-violet-700'>
            <div className='text-black'>
              You’ve selected an internal product. Click here to view its detailed life cycle and edit it. This will open the product graph
              in a new tab.
            </div>
            <button
              type='button'
              onClick={() => window.open(`/products/${formik.values.ingredient.id}/graph`, '_blank')}
              className='flex self-start items-center gap-x-2 px-2 py-1.5 bg-white rounded active:scale-[98%] text-neutral-700 hover:text-brand'
            >
              <FontAwesomeIcon className='size-4 flex' icon={light('arrow-up-right-from-square')} />
              <div>Edit internal product</div>
            </button>
          </div>
        )}
        {canUpdateStepInput && (
          <div className='col-span-2 flex gap-2 ml-2'>
            <input
              id={updateStepInputCheckboxId}
              type='checkbox'
              checked={updateStepInput}
              onChange={() => setUpdateStepInput((value) => !value)}
            />
            <label htmlFor={updateStepInputCheckboxId} className='select-none'>
              Automatically change input amount of the production step ({getSingleStepInputUsingIngredient()!.step.displayName}) to{' '}
              {formik.values.amount!.value}
              {formik.values.amount!.unit.name}
            </label>
          </div>
        )}
        {formik.values.ingredient?.placeholder && (
          <div className='col-span-2 flex flex-col gap-1'>
            <div className='pl-1.5'>Comment for ingredient</div>
            <div className='flex flex-col'>
              <Field name='ingredient.comment'>
                {(model: FieldProps<string>) => <InputV3 model={model} placeholder='Describe the ingredient…' />}
              </Field>
            </div>
          </div>
        )}
        <div className='col-span-2 flex flex-col gap-1'>
          <div className='pl-1.5'>Display name</div>
          <div className='flex flex-col'>
            <Field name='displayName'>
              {(model: FieldProps<string>) => (
                <InputV3 model={model} placeholder={formik.values.ingredient?.name ?? 'Defaults to ingredient name if left empty…'} />
              )}
            </Field>
          </div>
        </div>
        <TouchedAwareFieldArray name='nodes'>
          {(arrayModel) => (
            <>
              <div className='col-span-2 flex flex-col gap-1'>
                <div className='pl-1.5'>Suppliers</div>
                <div>
                  <Field name={arrayModel.name}>
                    {(model: FieldProps<IngredientSupplierNode[]>) => (
                      <SelectV3<IngredientSupplierNode>
                        multi
                        multiRepeated
                        model={model}
                        disabled={formik.values.ingredient?.restrictedSuppliers}
                        getOptionValue={({ supplier }) => supplier.id}
                        getOptionLabel={({ supplier }) => supplier.name}
                        menuPortalTarget={props.formRef.current}
                        loadOptions={(input, callback) => {
                          setNewSupplierName(input);
                          getSuppliers({
                            contains: input,
                            service: SupplierService.Ingredient,
                          }).ok(({ suppliers }) => callback(suppliers.map((supplier) => toSupplierOption(supplier, { noId: true }))));
                        }}
                        adjustChange={(value: IngredientSupplierNode[]) =>
                          value.map((option) => ({ ...option, id: option.id || newNodeId() }))
                        }
                        renderOptionBadge={({ supplier }) => (supplier.default ? <DefaultBadge /> : <></>)}
                        menuFooter={
                          !showSupplierForm && (
                            <SelectFooterAddButton onClick={() => setShowSupplierForm(true)} name={newSupplierName} label='new provider' />
                          )
                        }
                      />
                    )}
                  </Field>
                </div>
              </div>

              {formik.values.ingredient?.restrictedSuppliers && (
                <div className='flex gap-x-2 col-span-2 p-2 bg-violet-100 border border-violet-700 rounded-lg text-black'>
                  <FontAwesomeIcon className='size-5 self-center' icon={regular('info-circle')} />
                  <div className='text-sm'>
                    Supplier and country of origin were automatically taken from your internal product and can’t be edited to ensure there
                    are no gaps in your LCA. If you need to make a change, edit your internal product or duplicate and edit a new copy.
                  </div>
                </div>
              )}

              {showSupplierForm && (
                <div className='bg-[#F5F7FA] col-span-2 p-3 rounded-xl shadow-regular mt-3'>
                  <NewSupplierForm
                    name={newSupplierName}
                    formRef={props.formRef}
                    requiredServices={[SupplierService.Ingredient]}
                    onCancel={() => setShowSupplierForm(false)}
                    onCreated={(newSupplier) => {
                      formik.setFieldValue('nodes', [...formik.values.nodes, toSupplierOption(newSupplier)]);
                      setShowSupplierForm(false);
                    }}
                  />
                </div>
              )}
              {formik.values.nodes.length > 0 && (
                <div className='col-span-2 grid grid-cols-2 gap-4 mt-4'>
                  {formik.values.nodes.map((node, index) => (
                    <SupplierCard
                      disabled={formik.values.ingredient?.restrictedSuppliers}
                      key={index}
                      index={index}
                      data={node as SupplierNode}
                      arrayModel={arrayModel}
                      {...props}
                    />
                  ))}
                </div>
              )}
            </>
          )}
        </TouchedAwareFieldArray>
      </div>
    </TaggableFieldsContainer>
  );
});

const SupplierCard = (
  props: {
    index: number;
    data: SupplierNode;
    arrayModel: FieldArrayRenderProps;
    disabled: boolean;
  } & BodyProps,
) => {
  const formik = useFormikContext<IngredientNode>();
  const splitPercentRef = useRef<HTMLInputElement>(null);

  useEffectOnNextRenders(() => {
    if (document.activeElement === splitPercentRef.current) {
      adjustSupplierSplits(formik, props.data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.data.splitPercent]);

  return (
    <div className='flex flex-col justify-between gap-8 p-4 bg-neutral-50 shadow-[inset_0_0_6px_rgba(0,0,0,0.05)] rounded-lg'>
      <div className='flex flex-col gap-2'>
        <div className='flex justify-between gap-4 font-semibold text-lg text-neutral-900'>
          <div className='-ml-3 flex-1'>
            <Field name={`${props.arrayModel.name}.${props.index}.supplier`}>
              {(model: FieldProps<Entity>) => (
                <SelectV3
                  model={model}
                  disabled={props.disabled}
                  menuPortalTarget={props.formRef.current}
                  loadOptions={(input, callback) => {
                    getSuppliers({
                      contains: input,
                      service: SupplierService.Ingredient,
                    }).ok(({ suppliers }) => callback(suppliers));
                  }}
                  hideInputBorder
                  noClear
                />
              )}
            </Field>
          </div>
          <button
            type='button'
            disabled={props.disabled}
            className='flex justify-center items-center rounded-sm w-7 aspect-square'
            onClick={props.arrayModel.handleRemove(props.index)}
          >
            <FontAwesomeIcon className={cn({ 'text-zinc-500 cursor-not-allowed': props.disabled })} icon={regular('times')} size='lg' />
          </button>
        </div>
        {props.data.supplier.default ? (
          <div className='flex'>
            <DefaultBadge />
          </div>
        ) : (
          renderBadges(props.data, props.productFormik)
        )}
      </div>
      <div className='flex flex-col gap-4'>
        <div className='flex flex-col gap-1'>
          <div className='pl-1.5'>Split</div>
          <div>
            <TaggableField name={`${props.arrayModel.name}.${props.index}.splitPercent`} card>
              {(model: FieldProps<number>) => (
                <UnitInputV3
                  disabled={props.disabled}
                  inputRef={splitPercentRef}
                  model={model}
                  unit={{ options: [{ id: '', name: '%' }] }}
                />
              )}
            </TaggableField>
          </div>
        </div>
        <LocationSelect
          disabled={props.disabled}
          name={`${props.arrayModel.name}.${props.index}.location`}
          ingredient={formik.values.ingredient}
          includeOption={(option) =>
            !formik.values.nodes
              .filter(({ id }) => id !== props.data.id)
              .filter(({ supplier }) => supplier.id === props.data.supplier.id)
              .some((node) => node?.location?.id === option.id)
          }
          formRef={props.formRef}
        />
      </div>
    </div>
  );
};

const IngredientSelect = (
  props: BodyProps & {
    model: FieldProps<IngredientV3>;
  },
) => {
  const controlEvents = useControlEvents();

  return (
    <ReactSelectAsync
      autoFocus
      isClearable
      placeholder='Choose…'
      loadingMessage={() => 'Searching…'}
      noOptionsMessage={() => 'No matches found…'}
      getOptionValue={({ id }) => id}
      getOptionLabel={({ name }) => name}
      value={props.model.field.value}
      onChange={(value) => {
        controlEvents!.touched(props.model);
        props.model.form.setFieldValue(props.model.field.name, value);
      }}
      defaultOptions
      loadOptions={(input, callback) => {
        searchIngredientsV3(input).ok(({ customer, sustained }) =>
          callback([
            {
              label: 'Internal Products',
              options: customer,
            },
            {
              label: 'Generic Ingredients',
              options: sustained,
            },
          ]),
        );
      }}
      onMenuOpen={() => controlEvents!.touched(props.model)}
      menuPortalTarget={props.formRef.current}
      formatGroupLabel={(data) => (
        <div className='flex items-center gap-1.5'>
          <div>{data.label}</div>
        </div>
      )}
      components={{
        ...Components,
        Option: IngredientSelectOption,
      }}
      {...{ model: props.model }}
    />
  );
};

export const IngredientSelectOption = (props: OptionProps<IngredientV3>) => (
  <components.Option
    {...props}
    getStyles={() => ({
      ...props.getStyles('option', props),
      padding: '',
      cursor: 'pointer',
      color: '',
      backgroundColor: '',
      ':active': {},
    })}
    className={cn(
      'px-2 py-1',
      (() => {
        if (props.isSelected) {
          if (props.isFocused) {
            return 'bg-brandDark text-white';
          }

          return 'bg-brand text-white';
        }

        if (props.isFocused) {
          return 'bg-neutral-100';
        }

        return '';
      })(),
    )}
  >
    <div
      className={cn('flex items-center justify-between gap-4 px-2 py-1 rounded-xl', {
        'bg-amber-50': !props.isSelected && !props.isFocused && props.data.type === IngredientType.IntermediateProduct,
      })}
    >
      <div>
        {props.data.type === IngredientType.IntermediateProduct ? `${props.data.skuId} - ` : ''}
        {props.children}
      </div>
    </div>
  </components.Option>
);

const AmountFromPercentOfNetWidget = (props: BodyProps) => {
  const ingredientFormik = useFormikContext<IngredientNode>();
  const [popover, setPopover] = useState(false);

  return (
    <TooltipV3
      parentControl={{
        open: popover,
        onOpenChange: setPopover,
      }}
      offsetMain={2}
      offsetCross={14}
      placement='bottom-end'
      content={
        <Formik
          initialValues={{
            value: '',
          }}
          validationSchema={yup.object().shape({
            value: yup.number().positive().max(100).required(),
          })}
          onSubmit={({ value }) => {
            if (typeof value === 'number') {
              ingredientFormik.setFieldValue('amount.value', roundAmount((value / 100) * props.productFormik.values.amount.value));
            }

            setPopover(false);
          }}
        >
          {(formik) => (
            <div className='flex flex-col gap-3 w-[350px] p-4 bg-white rounded-xl border drop-shadow-xl'>
              <Field name='value'>
                {(model: FieldProps<number>) => (
                  <UnitInputV3
                    autoFocus
                    model={model}
                    placeholder={`Enter % of net amount (${props.productFormik.values.amount.value}${props.productFormik.values.amount.unit.name})`}
                    unit={{
                      options: [{ id: '%', name: '%' }],
                    }}
                    onKeyDown={(event) => {
                      if (event.key === 'Enter') {
                        event.preventDefault();
                        formik.submitForm();
                      }
                    }}
                  />
                )}
              </Field>
              <button
                type='button'
                className='self-start flex justify-center bg-brand rounded-full px-4 py-1.5 text-white font-semibold active:scale-95 disabled:bg-neutral-300'
                onClick={() => formik.submitForm()}
              >
                Convert
              </button>
            </div>
          )}
        </Formik>
      }
    >
      <button type='button' className='text-brand font-semibold text-xs' onClick={() => setPopover((current) => !current)}>
        Enter as %
      </button>
    </TooltipV3>
  );
};
