import { useLocation, useNavigate } from 'react-router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';

enum Reason {
  UserNotFound = 'user-not-found',
  ProductNotFound = 'product-not-found',
}

export const NotFound = () => {
  const navigate = useNavigate();
  const state = useLocation().state as { requestSid: string; response_code: number; message: Reason };

  const navigationButton = (path: any, label: string) => (
    <button
      onClick={() => navigate(path)}
      type='button'
      className='px-4 py-2 rounded-full bg-slate-200 text-violet-950 text-sm font-semibold'
    >
      {label}
    </button>
  );

  return (
    <div className='-my-10 h-full w-full flex justify-center items-center'>
      <div className='p-4 flex flex-col gap-y-4 text-zinc-900 items-center border rounded-2xl shadow text-center'>
        <FontAwesomeIcon className='size-10' icon={regular('file-slash')} />
        <div className='tracking-wide uppercase text-xs'>{state.requestSid}</div>
        {(() => {
          switch (state.message) {
            case Reason.UserNotFound:
              return (
                <>
                  <div className='text-lg font-semibold'>Sorry, we couldn’t find what you were looking for</div>
                  <div className='max-w-72'>This user might have been deleted from this workspace</div>
                  {navigationButton(-1, 'Back')}
                </>
              );
            case Reason.ProductNotFound:
              return (
                <>
                  <div className='text-lg font-semibold'>Sorry, we couldn’t find what you were looking for</div>
                  <div className='max-w-72'>This product might have been deleted from this workspace</div>
                  {navigationButton('/', 'Home')}
                </>
              );

            default:
              return (
                <>
                  <div className='text-lg font-semibold'>Sorry, we couldn’t find what you were looking for</div>
                  {navigationButton('/', 'Home')}
                </>
              );
          }
        })()}
      </div>
    </div>
  );
};
