import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { useApiQuery } from '../../../../hooks/useApiQuery';
import { getWorkspaces } from '../../../../api';
import { convertUTCDateToLocal } from '../../../../components/datepicker/utils';
import { differenceInDays } from 'date-fns';
import { useNavigate } from 'react-router';

interface Props {
  customerSid: string;
}

export const Workspaces = (props: Props) => {
  const navigate = useNavigate();
  const { data } = useApiQuery(getWorkspaces(props.customerSid!));

  if (!data) {
    return <></>;
  }

  return (
    <>
      <div className='flex items-center justify-between h-10'>
        <div className='font-semibold text-base text-zinc-800'>Workspaces</div>
        <button
          onClick={() => navigate('./new')}
          className='text-violet-950 text-sm font-semibold leading-tight px-4 py-2 bg-slate-200 rounded-lg'
        >
          Create new workspace
        </button>
      </div>
      <div className='grid grid-cols-[2fr_3fr_1fr_2fr_2fr_2fr_1fr] gap-x-4'>
        <div className='grid grid-cols-subgrid col-span-7 uppercase text-xs text-zinc-600 bg-slate-50 p-2'>
          <div>name</div>
          <div>id</div>
          <div>trial</div>
          <div>billing mode</div>
          <div>target product count</div>
          <div>methodology</div>
          <div>brand</div>
        </div>

        <div className='grid grid-cols-subgrid col-span-7 divide-y'>
          {data.map((workspace, i) => (
            <div className='grid grid-cols-subgrid col-span-7 items-center *:truncate' key={i}>
              <div title={workspace.name} className='flex items-center gap-x-3 py-4'>
                {workspace.logoUrl ? (
                  <img className='shrink-0 size-8 rounded-full overflow-hidden' src={workspace.logoUrl} alt='' />
                ) : (
                  <div className='shrink-0 size-8 flex justify-center items-center bg-violet-200 rounded-full'>
                    <FontAwesomeIcon icon={regular('building')} />
                  </div>
                )}
                <div className='truncate'>{workspace.name}</div>
              </div>
              <div className='flex items-center gap-x-1' title={workspace.workspaceSid}>
                <div className='truncate'>{workspace.workspaceSid}</div>
                <button className='active:scale-95 hover:text-brand' onClick={() => navigator.clipboard.writeText(workspace.workspaceSid)}>
                  <FontAwesomeIcon icon={regular('copy')} />
                </button>
              </div>
              <div title={workspace.trialUntil ?? ''}>
                {workspace.trialUntil
                  ? `${differenceInDays(new Date(convertUTCDateToLocal(workspace.trialUntil)), new Date()) + 1} days`
                  : '-'}
              </div>
              <div title={workspace.billingMode?.name}>{workspace.billingMode?.name ?? '-'}</div>
              <div title={workspace.targetSkuCount.toString()}>{workspace.targetSkuCount}</div>
              <div title={workspace.methodology.name}>{workspace.methodology.name}</div>
              <div title={workspace.brand.name}>{workspace.brand.name}</div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};
