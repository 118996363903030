import { light, regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cn from 'classnames';
import { Field, FieldArray, FieldArrayRenderProps, FieldProps, Formik, useFormikContext } from 'formik';
import isEqual from 'lodash/isEqual';
import uniq from 'lodash/uniq';
import { Fragment, RefObject, SetStateAction, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import {
  Entity,
  IngredientType,
  MappedIngredient,
  MappedItem,
  Mapping,
  MappingProduct,
  Tag,
  getMapping,
  getMappingProducts,
  updateMapping,
} from '../../../../api';
import InfiniteScroll from '../../../../components/InfiniteScroll';
import { InputV3 } from '../../../../components/InputV3';
import { ModalApi } from '../../../../components/ModalV3';
import { SelectV3 } from '../../../../components/SelectV3';
import { TooltipV3 } from '../../../../components/TooltipV3';
import { UnitInputV3 } from '../../../../components/UnitInputV3';
import { newNodeId } from '../../Products/Details/dataModel';
import { useLists } from '../../../../hooks/useLists';
import { ManageEntity } from '../Overview';
import { StatusBadge } from '../StatusBadge';
import { MappingIngredientSelect } from './components/MappingIngredientSelect';
import { ProductsListModal } from './components/ProductsListModal';
import { TagBadge } from './components/TagBadge';
import { getInitialFormData, mappingValidationSchema } from './dataModel';

interface Props {
  expanded: string[];
  handleExpandedState: (value: string) => void;
  detailsHeaderRef: RefObject<HTMLDivElement>;
  mappingBodyHeight?: number;
  secondaryListHeaderRef: RefObject<HTMLDivElement>;
  secondaryListHeight?: number;
  mappingsData: ManageEntity<Mapping[]>;
  setMappingsData: (data: SetStateAction<ManageEntity<Mapping[]>>) => void;
  fetchNextMappingsPage: () => void;
  selectedCategory: string;
  selectedMapping: Mapping;
  setSelectedMapping: (value: SetStateAction<Mapping | null>) => void;
  mappingProducts: { list: MappingProduct[]; mappingId: string; searchString: string };
  setMappingProducts: (data: SetStateAction<{ list: MappingProduct[]; mappingId: string; searchString: string }>) => void;
  reload: (value: boolean) => void;
  tagsOnboardingRef: RefObject<ModalApi>;
}

export const DetailsView = (props: Props) => {
  const [pressedSave, setPressedSave] = useState(false);
  const [initialValues, setInitialValues] = useState<Mapping | null>(null);
  const [waiting, setWaiting] = useState(!props.selectedMapping.editable);
  const [editMode, setEditMode] = useState(false);
  const [collapsed, setCollapsed] = useState<Array<string>>([]);
  const navigate = useNavigate();

  const handleCollapse = (id: string) => {
    if (collapsed.includes(id)) {
      setCollapsed(collapsed.filter((item) => item !== id));
    } else {
      setCollapsed([...collapsed, id]);
    }
  };

  useEffect(() => {
    if (!props.selectedMapping.editable) {
      setWaiting(true);
    } else {
      setWaiting(false);
    }
    const intervalId = setInterval(() => {
      if (!props.selectedMapping.editable) {
        getMapping(props.selectedMapping.workspaceMappingId).ok((data) => {
          props.setSelectedMapping(data);
        });
      } else {
        setWaiting(false);
        clearInterval(intervalId);
      }
    }, 3000);

    return () => clearInterval(intervalId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.selectedMapping]);

  return (
    <div className='grid grid-cols-[250px_auto] bg-zinc-50'>
      <div>
        <div
          ref={props.secondaryListHeaderRef}
          className='h-20 flex items-center border-b border-r border-zinc-200 bg-white px-6 text-lg font-semibold'
        >
          Mappings
        </div>
        <div className='flex flex-col divide-y border-r border-b border-zinc-200 max-w-[250px]'>
          {props.secondaryListHeight && (
            <InfiniteScroll
              height={props.secondaryListHeight}
              dataLength={props.mappingsData.list.length}
              next={props.fetchNextMappingsPage}
              hasMore={props.mappingsData.nextPageToken !== ''}
              loader={<></>}
            >
              <div className='pl-6 p-3 border-b border-zinc-200 font-semibold sticky top-0 bg-neutral-50'>Extracted data</div>

              <div className='divide-y'>
                {props.mappingsData.list.map((mapping, i) => (
                  <div
                    onClick={() => {
                      navigate(`../mappings/${props.selectedCategory}/${mapping.workspaceMappingId}`);
                    }}
                    className={cn(
                      'flex items-center justify-between pl-6 p-3 hover:cursor-pointer',
                      props.selectedMapping.workspaceMappingId === mapping.workspaceMappingId
                        ? 'hover:bg-slate-200 bg-slate-200'
                        : mapping.state === Tag.Unmatched
                        ? 'hover:bg-slate-100 bg-red-50'
                        : 'hover:bg-slate-100 bg-white',
                    )}
                    key={i}
                  >
                    <div title={mapping.extractedData} className='truncate'>
                      {mapping.extractedData}
                    </div>
                    <div className='flex items-center gap-1'>
                      {(() => {
                        const innerUniqQualifiers = uniq(
                          (getInitialFormData(mapping).mappedData.items as { qualifier?: Tag }[])
                            .filter((item, i) => i > 0 && item)
                            .flatMap(({ qualifier }) => qualifier),
                        ).filter((qualifier) => qualifier !== undefined);

                        return (
                          <Fragment>
                            <TagBadge state={mapping.state} />
                            {innerUniqQualifiers.length > 0 &&
                              (!collapsed.includes(mapping.workspaceMappingId) ? (
                                <button
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    handleCollapse(mapping.workspaceMappingId);
                                  }}
                                  className='flex leading-none whitespace-nowrap px-1.5 py-1 rounded bg-slate-100 text-[10px] font-semibold'
                                >
                                  {`+${innerUniqQualifiers.length} other${innerUniqQualifiers.length > 1 ? 's' : ''}`}
                                </button>
                              ) : (
                                <button
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    handleCollapse(mapping.workspaceMappingId);
                                  }}
                                  className='flex items-center justify-center flex-wrap gap-0.5'
                                >
                                  {innerUniqQualifiers.map((qualifier, index) => (
                                    <div key={index}>
                                      <TagBadge state={qualifier!} />
                                    </div>
                                  ))}
                                </button>
                              ))}
                          </Fragment>
                        );
                      })()}
                    </div>
                  </div>
                ))}
              </div>
            </InfiniteScroll>
          )}
        </div>
      </div>

      <Formik<Mapping>
        enableReinitialize
        validateOnBlur={pressedSave}
        validateOnChange={pressedSave}
        initialValues={getInitialFormData(props.selectedMapping)}
        validationSchema={mappingValidationSchema}
        onSubmit={(values) => {
          setWaiting(true);

          const payload: Mapping = {
            ...values,
            state: Tag.UserInput,
            mappedData: {
              items: values.mappedData.items.map((item) => ({
                ...item,
                qualifier: isEqual(
                  initialValues?.mappedData.items.find((initialItem) => {
                    if (item.ingredient) {
                      return initialItem.ingredient?.id === item.ingredient.id;
                    }

                    if (item.packaging) {
                      return initialItem.packaging?.id === item.packaging.id;
                    }

                    if (item.materials) {
                      return initialItem.materials?.every((material) => item.materials?.find((m) => m.id === material.id));
                    }

                    if (item.conservation) {
                      return initialItem.conservation?.id === item.conservation.id;
                    }

                    return false;
                  }),
                  item,
                )
                  ? item.qualifier
                  : Tag.UserInput,
              })),
            },
          };
          updateMapping(values.workspaceMappingId, payload).call({
            ok: (response) => {
              setEditMode(false);
              props.setSelectedMapping(response);
            },
            fail: () => setWaiting(false),
          });
        }}
      >
        <Content
          expanded={props.expanded}
          handleExpandedState={props.handleExpandedState}
          detailsHeaderRef={props.detailsHeaderRef}
          mappingBodyHeight={props.mappingBodyHeight}
          selectedMapping={props.selectedMapping}
          setSelectedMapping={props.setSelectedMapping}
          selectedCategory={props.selectedCategory}
          setPressedSave={setPressedSave}
          setInitialValues={setInitialValues}
          mappingProducts={props.mappingProducts}
          setMappingProducts={props.setMappingProducts}
          waiting={waiting}
          tagsOnboardingRef={props.tagsOnboardingRef}
          editMode={editMode}
          setEditMode={setEditMode}
        />
      </Formik>
    </div>
  );
};

interface ContentProps {
  expanded: string[];
  handleExpandedState: (value: string) => void;
  detailsHeaderRef: RefObject<HTMLDivElement>;
  mappingBodyHeight?: number;
  selectedCategory: string;
  selectedMapping: Mapping;
  setSelectedMapping: (value: SetStateAction<Mapping | null>) => void;
  setPressedSave: (values: boolean) => void;
  setInitialValues: (values: SetStateAction<Mapping | null>) => void;
  mappingProducts: { list: MappingProduct[]; mappingId: string; searchString: string };
  setMappingProducts: (data: SetStateAction<{ list: MappingProduct[]; mappingId: string; searchString: string }>) => void;
  waiting: boolean;
  tagsOnboardingRef: RefObject<ModalApi>;
  editMode: boolean;
  setEditMode: (value: boolean) => void;
}

const Content = (props: ContentProps) => {
  const navigate = useNavigate();
  const formik = useFormikContext<Mapping>();
  const modalRef = useRef<ModalApi>(null);

  useEffect(() => {
    if (formik.values.mappedData.items.find((item) => item.materials && item.materials.length === 0)) {
      props.setEditMode(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.mappedData.items]);

  return (
    <div className='bg-white overflow-hidden'>
      <div ref={props.detailsHeaderRef}>
        <div className='h-20 flex items-center justify-between gap-2 border-b border-zinc-200 bg-white px-6'>
          <div className='flex items-center gap-x-2 truncate'>
            <button
              type='button'
              onClick={() => navigate(`../mappings/${props.selectedCategory}`)}
              className='h-8 aspect-square flex items-center justify-center bg-[#E8EAF5] rounded-lg hover:bg-white hover:border-2 hover:border-brand'
            >
              <FontAwesomeIcon className='text-xl text-brand' icon={regular('chevron-left')} />
            </button>
            <div className='text-xl font-semibold truncate'>{formik.values.extractedData}</div>
          </div>
          <div className='flex items-center gap-4'>
            {props.waiting && (
              <TooltipV3
                placement='bottom'
                content={
                  <div className='flex px-3 py-1.5 bg-brandDark text-white whitespace-normal text-xs w-80 rounded-lg shadow'>
                    We’re propagating your changes across all products using this mapping. This may take a while.
                  </div>
                }
              >
                <div className='flex gap-1 items-center bg-amber-100 text-amber-800 text-xs whitespace-nowrap rounded px-1 py-0.5'>
                  <FontAwesomeIcon size='sm' icon={regular('clock')} />
                  Updating products...
                </div>
              </TooltipV3>
            )}
            {isEqual(formik.initialValues, formik.values) && (
              <button
                onClick={() => props.tagsOnboardingRef.current?.open()}
                className='flex items-center gap-1.5 font-semibold text-brandDark active:scale-95'
              >
                <div className='h-5 aspect-square flex items-center justify-center bg-lime-200 mx-0.5 rounded-full'>
                  <FontAwesomeIcon icon={light('lightbulb')} />
                </div>
                Badges
              </button>
            )}
            {!isEqual(formik.initialValues, formik.values) && (
              <div className='flex items-center gap-3'>
                {!props.waiting && <StatusBadge state='unsaved' />}
                <button
                  type='button'
                  className='flex self-center border-2 border-[#220066] rounded-full px-4 py-1 text-[#220066] font-semibold active:scale-95'
                  onClick={() => formik.setValues(formik.initialValues)}
                >
                  Cancel
                </button>
                <div className='flex gap-2 items-center'>
                  <button
                    disabled={props.waiting}
                    type='submit'
                    className='self-center text-center border-2 border-brand bg-brand rounded-full px-4 py-1 text-white font-semibold'
                    onClick={() => {
                      props.setInitialValues(formik.initialValues);
                      props.setPressedSave(true);
                      formik.handleSubmit();
                    }}
                  >
                    Save
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>

        {(() => {
          switch (props.selectedMapping.state) {
            case Tag.Unmatched:
              const title =
                props.selectedMapping.type === 'ingredient'
                  ? 'Ingredient could not be automatically mapped'
                  : 'Packaging could not be automatically mapped';
              const content =
                props.selectedMapping.type === 'ingredient'
                  ? 'We could not successfully map this ingredient to an existing one in our database or yours. Select the correct ingredient below or select ‘Other’ if you’d like us to create a new ingredient for you.'
                  : 'We could not successfully map this packaging to an existing one in our database or yours. Select the correct packaging below or select ‘Other’ if you’d like us to create a new packaging for you.';

              return (
                <div className='flex flex-col gap-3 px-6 py-4 bg-red-50 border-b border-zinc-200'>
                  <button type='button' onClick={() => props.handleExpandedState(title)} className='flex justify-between items-center'>
                    <div className='flex items-center gap-2 text-sm font-semibold'>
                      <FontAwesomeIcon className='text-red-500' icon={regular('exclamation-circle')} />
                      <div className='font-semibold text-base'>{title}</div>
                    </div>
                    <FontAwesomeIcon
                      className={cn('h-4 aspect-square', { '-rotate-90': !props.expanded.includes(title) })}
                      icon={solid('chevron-down')}
                    />
                  </button>
                  {props.expanded.includes(title) && <div className='text-sm'>{content}</div>}
                </div>
              );
            default:
              return (
                <div className='flex flex-col gap-3 px-6 py-4 bg-neutral-50 border-b border-zinc-200'>
                  <button
                    type='button'
                    onClick={() => props.handleExpandedState('Item mapping')}
                    className='flex justify-between items-center'
                  >
                    <div className='font-semibold text-base'>Item mapping</div>
                    <FontAwesomeIcon
                      className={cn('h-4 aspect-square', { '-rotate-90': !props.expanded.includes('Item mapping') })}
                      icon={solid('chevron-down')}
                    />
                  </button>
                  {props.expanded.includes('Item mapping') && (
                    <div>
                      You will find below the automatic mapping for the selected item. Changing anything here will make sure all products
                      where the same name was found will use the new mapping instead. Alternatively, you can change anything at the product
                      level.
                    </div>
                  )}
                </div>
              );
          }
        })()}
      </div>

      {props.mappingBodyHeight && (
        <div style={{ height: props.mappingBodyHeight }} className='overflow-y-auto'>
          <div className='uppercase pl-6 py-2 text-zinc-500 text-xs bg-slate-50'>original</div>
          <div className='px-6 py-4 grid grid-cols-3 gap-3 items-center'>
            <div className='text-zinc-500'>Extracted data</div>
            <div title={formik.values.extractedData} className='col-span-2 text-zinc-400 truncate px-3'>
              {formik.values.extractedData}
            </div>

            {/* extractedCategory only for packaging */}
            {formik.values.extractedCategory && (
              <>
                <div />
                <div className='col-span-2 text-zinc-400 truncate px-3'>{formik.values.extractedCategory}</div>
              </>
            )}

            <div className='text-zinc-500'>ID</div>
            <div title={formik.values.internalId} className='col-span-2 text-zinc-400 truncate px-3'>
              {formik.values.internalId}
            </div>

            <div className='text-zinc-500'>Used in</div>
            <div className='flex items-center px-3'>
              <ProductsListModal
                mapping={formik.values}
                mappingProducts={props.mappingProducts}
                setMappingProducts={props.setMappingProducts}
                modalRef={modalRef}
              >
                <button
                  disabled={formik.values.usedIn === 0}
                  className='flex items-center rounded-full border p-0.5 pr-1.5 gap-1 bg-white'
                  onClick={() => {
                    getMappingProducts(formik.values.workspaceMappingId, { contains: '' }).call({
                      ok: (data) => {
                        props.setMappingProducts((current) => ({
                          ...current,
                          mappingId: formik.values.workspaceMappingId,
                          list: data.products,
                        }));
                        modalRef.current?.open();
                      },
                    });
                  }}
                >
                  <div
                    title={formik.values.usedIn.toString()}
                    className='flex items-center justify-center h-5 aspect-square rounded-full bg-slate-100 text-xs'
                  >
                    {formik.values.usedIn > 99 ? '99+' : formik.values.usedIn}
                  </div>
                  <div className='mb-0.5'>products</div>
                </button>
              </ProductsListModal>
            </div>
            <div />
          </div>
          <div className='grid grid-cols-3 gap-3 uppercase px-6 py-2 text-zinc-500 text-xs bg-slate-50'>
            <div>mapped to</div>
            <div />
            <div>state</div>
          </div>

          {/* TODO: disable editing mappings sc-2719 in prod */}
          <div {...{ inert: process.env.REACT_APP_ENV === 'prod' ? '' : undefined }} className='px-6 py-4 flex flex-col gap-3'>
            <Field name='type'>
              {(model: FieldProps<string>) => {
                const ingredient: { id: 'ingredient'; name: string } = {
                  id: 'ingredient',
                  name: 'Ingredient',
                };

                const packaging: { id: 'packaging'; name: string } = {
                  id: 'packaging',
                  name: 'Packaging',
                };

                return (
                  <div className='grid grid-cols-3 gap-3 items-center'>
                    <div className='text-zinc-500'>Group</div>
                    <SelectV3<{ id: 'ingredient' | 'packaging'; name: string }>
                      hideInputBorder={model.field.value !== null}
                      onActions={{
                        add: (value) => {
                          formik.setFieldValue('type', value.id);
                          formik.setValues(
                            getInitialFormData({
                              ...props.selectedMapping,
                              type: value.id,
                              mappedData: {
                                items: [],
                              },
                            }),
                          );
                        },
                        remove: () => {
                          formik.setFieldValue('type', null);
                          formik.setFieldValue('mappedData.items', []);
                        },
                        clear: () => {
                          formik.setFieldValue('type', null);
                          formik.setFieldValue('mappedData.items', []);
                        },
                      }}
                      convertOptions={{
                        fromModel: (value: string) => {
                          if (value === 'ingredient') {
                            return ingredient;
                          }

                          if (value === 'packaging') {
                            return packaging;
                          }
                        },
                        toModel: (value: Entity) => {
                          if (value.id === 'packaging') {
                            return packaging.id;
                          }

                          if (value.id === 'ingredient') {
                            return ingredient.id;
                          }
                        },
                      }}
                      model={model}
                      options={[ingredient, packaging]}
                    />
                    <div />
                  </div>
                );
              }}
            </Field>

            <FieldArray
              name='mappedData.items'
              render={(arrayModel) => (
                <div className='grid grid-cols-3 gap-3 items-center'>
                  {formik.values.mappedData.items.map((item, i) => (
                    <Fragment key={i}>
                      <Row index={i} item={item} arrayModel={arrayModel} editMode={props.editMode} setEditMode={props.setEditMode} />
                    </Fragment>
                  ))}
                </div>
              )}
            />
          </div>
        </div>
      )}
    </div>
  );
};

interface RowProps {
  index: number;
  item: MappedItem;
  arrayModel: FieldArrayRenderProps;
  editMode: boolean;
  setEditMode: (value: boolean) => void;
}

const Row = (props: RowProps) => {
  const formik = useFormikContext<Mapping>();
  const lists = useLists();

  const getSubTypes = (materialId: string) =>
    lists.materialSubTypes.filter((subType) =>
      lists.packagingMaterials.find(({ id }) => id === materialId)!.materialSubTypes.includes(subType.id),
    );

  useEffect(() => {
    if (formik.values.mappedData.items[props.index].ingredient) {
      if (
        props.item.ingredient &&
        props.item.qualifier !== Tag.UserInput &&
        formik.initialValues.mappedData.items[props.index].ingredient?.name !== formik.values.mappedData.items[props.index].ingredient?.name
      ) {
        formik.setFieldValue(`${props.arrayModel.name}.${props.index}.displayName`, props.item.ingredient.name);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.mappedData.items[props.index].ingredient?.name]);

  useEffect(() => {
    if (formik.values.mappedData.items[props.index].packaging) {
      if (
        props.item.packaging &&
        props.item.qualifier !== Tag.UserInput &&
        formik.initialValues.mappedData.items[props.index].packaging?.name !== formik.values.mappedData.items[props.index].packaging?.name
      ) {
        formik.setFieldValue(`${props.arrayModel.name}.${props.index}.displayName`, props.item.packaging.name);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.mappedData.items[props.index].packaging?.name]);

  return (
    <>
      {(() => {
        if (props.item.ingredient !== undefined) {
          return (
            <>
              <Field name={`${props.arrayModel.name}.${props.index}.ingredient`}>
                {(model: FieldProps<MappedIngredient>) => (
                  <Fragment>
                    <div className='text-zinc-500'>Ingredient name</div>
                    <MappingIngredientSelect hideInputBorder={model.field.value !== null} model={model} />
                    <div className={cn('flex', { invisible: !isEqual(model.meta.initialValue, model.field.value) })}>
                      <TagBadge state={props.item.qualifier} />
                    </div>
                  </Fragment>
                )}
              </Field>

              {props.item.ingredient?.placeholder && (
                <>
                  <div className='text-zinc-500'>Comment for ingredient</div>
                  <Field name={`${props.arrayModel.name}.${props.index}.ingredient.comment`}>
                    {(model: FieldProps<string | undefined | null>) => (
                      <InputV3
                        hideInputBorder={!(model.field.value === undefined || model.field.value === null)}
                        model={model}
                        placeholder='Describe the ingredient…'
                      />
                    )}
                  </Field>
                  <div />
                </>
              )}

              <div className='text-zinc-500'>Display name</div>
              <Field name={`${props.arrayModel.name}.${props.index}.displayName`}>
                {(model: FieldProps<string | null>) => (
                  <InputV3 hideInputBorder={model.field.value !== null && model.field.value !== undefined} model={model} />
                )}
              </Field>
              <div />

              <div className='text-zinc-500 py-2'>Type</div>
              <Field name={`${props.arrayModel.name}.${props.index}.ingredient.type`}>
                {(model: FieldProps<string>) => {
                  const ingredientTypeOptions = [
                    {
                      id: IngredientType.Generic,
                      name: 'Generic ingredient',
                    },
                    {
                      id: IngredientType.Consumer,
                      name: 'Consumer ingredient',
                    },
                    {
                      id: IngredientType.IntermediateProduct,
                      name: 'Intermediate Product',
                    },
                  ];

                  return (
                    <div className='py-2 pl-3 text-zinc-400'>{ingredientTypeOptions.find(({ id }) => model.field.value === id)?.name}</div>
                  );
                }}
              </Field>
              <div />
            </>
          );
        }

        if (props.item.conservation !== undefined) {
          return (
            <>
              <Field name={`${props.arrayModel.name}.${props.index}.conservation`}>
                {(model: FieldProps<Entity>) => (
                  <Fragment>
                    <div className='text-zinc-500'>Conservation</div>

                    <SelectV3<Entity> hideInputBorder={model.field.value !== null} model={model} options={lists.conservationRequirements} />

                    <div className={cn('flex', { invisible: !isEqual(model.meta.initialValue, model.field.value) })}>
                      <TagBadge state={props.item.qualifier} />
                    </div>
                  </Fragment>
                )}
              </Field>
            </>
          );
        }

        if (props.item.packaging !== undefined) {
          return (
            <>
              <Field name={`${props.arrayModel.name}.${props.index}.packaging`}>
                {(packagingModel: FieldProps<Entity>) => {
                  const clearMaterialsOnPackagingChange = () => {
                    const materialsIndex = formik.values.mappedData.items.findIndex((item) => item.materials !== undefined);
                    if (materialsIndex !== -1) {
                      formik.setFieldValue(`mappedData.items.${materialsIndex}.materials`, []);
                    }
                  };

                  return (
                    <Fragment>
                      <div className='text-zinc-500'>Packaging type</div>
                      <SelectV3<Entity>
                        hideInputBorder={packagingModel.field.value !== null}
                        onActions={{
                          add: (value) => {
                            formik.setFieldValue(packagingModel.field.name, value);
                            clearMaterialsOnPackagingChange();
                          },
                          remove: () => {
                            formik.setFieldValue(packagingModel.field.name, null);
                            clearMaterialsOnPackagingChange();
                          },
                          clear: () => {
                            formik.setFieldValue(packagingModel.field.name, null);
                            clearMaterialsOnPackagingChange();
                          },
                        }}
                        model={packagingModel}
                        options={lists.packagingTypes}
                      />
                      <div
                        className={cn('flex', {
                          invisible: !isEqual(packagingModel.meta.initialValue, packagingModel.field.value),
                        })}
                      >
                        <TagBadge state={props.item.qualifier} />
                      </div>
                    </Fragment>
                  );
                }}
              </Field>

              {props.item.packaging?.placeholder && (
                <Fragment>
                  <div className='text-zinc-500'>Comment for packaging</div>
                  <Field name={`${props.arrayModel.name}.${props.index}.packaging.comment`}>
                    {(model: FieldProps<string | null | undefined>) => (
                      <InputV3
                        hideInputBorder={!(model.field.value === undefined || model.field.value === null)}
                        model={model}
                        placeholder='Describe the packaging…'
                      />
                    )}
                  </Field>
                  <div />
                </Fragment>
              )}

              <div className='text-zinc-500'>Display name</div>
              <Field name={`${props.arrayModel.name}.${props.index}.displayName`}>
                {(model: FieldProps<string | null | undefined>) => (
                  <InputV3 hideInputBorder={model.field.value !== undefined && model.field.value !== null} model={model} />
                )}
              </Field>
              <div />
            </>
          );
        }

        if (props.item.materials !== undefined) {
          return props.editMode ? (
            <>
              {(() => {
                const packaging = lists.packagingTypes.find((type) =>
                  formik.values.mappedData.items.find((item) => type.id === item.packaging?.id),
                );

                return (
                  <Field name={`${props.arrayModel.name}.${props.index}.materials`}>
                    {(model: FieldProps<Entity[]>) => (
                      <Fragment>
                        <div className='text-zinc-500 flex self-start pt-2'>Composition</div>
                        <SelectV3
                          hideInputBorder={model.field.value.length !== 0}
                          multi
                          multiRepeated
                          model={model}
                          options={lists.packagingMaterials
                            .filter(({ id }) => packaging?.packagingMaterials.includes(id))
                            .map((material) => ({
                              ...material,
                              id: newNodeId(),
                              materialId: material.id,
                              ...(getSubTypes(material.id).length === 1 && !material.placeholder
                                ? { subType: getSubTypes(material.id)[0] }
                                : {}),
                            }))}
                        />
                        <div
                          className={cn('flex self-start pt-2', {
                            invisible: !isEqual(model.meta.initialValue, model.field.value),
                          })}
                        >
                          <TagBadge state={props.item.qualifier} />
                        </div>
                      </Fragment>
                    )}
                  </Field>
                );
              })()}

              <FieldArray name={`${props.arrayModel.name}.${props.index}.materials`}>
                {(materialsArrayModel) => {
                  return (
                    <div className='col-span-3 grid grid-cols-3 gap-3 mt-3'>
                      {props.item.materials?.map((material, j) => (
                        <div key={j} className='flex flex-col gap-2 p-2 bg-neutral-50 shadow-[inset_0_0_6px_rgba(0,0,0,0.05)] rounded-lg'>
                          <div className='flex justify-between gap-4 font-semibold text-base text-neutral-900'>
                            <div className='truncate'>{material.name}</div>
                            <button
                              type='button'
                              className='flex justify-center items-center rounded-sm w-7 aspect-square'
                              onClick={materialsArrayModel.handleRemove(j)}
                            >
                              <FontAwesomeIcon icon={regular('times')} size='sm' />
                            </button>
                          </div>
                          <div className='flex-1 flex flex-col justify-between gap-4'>
                            {!material.placeholder && (
                              <div className='flex flex-col gap-1'>
                                <div className='pl-1.5'>Type</div>
                                <Field name={`${materialsArrayModel.name}.${j}.subType`}>
                                  {(model: FieldProps<Entity>) => <SelectV3 model={model} options={getSubTypes(material.materialId)} />}
                                </Field>
                              </div>
                            )}

                            {(material.placeholder || material.subType?.placeholder) && (
                              <div className='flex flex-col gap-1'>
                                <div className='pl-1.5'>Comment</div>
                                <div className='flex flex-col'>
                                  <Field name={`${materialsArrayModel.name}.${j}.comment`} card>
                                    {(model: FieldProps<string>) => (
                                      <InputV3
                                        model={model}
                                        placeholder={`Describe the ${material.subType?.placeholder ? 'type' : 'material'}…`}
                                      />
                                    )}
                                  </Field>
                                </div>
                              </div>
                            )}

                            <div className='flex flex-col gap-1'>
                              <div className='pl-1.5'>Composition</div>
                              <Field name={`${materialsArrayModel.name}.${j}.compositionPercent`}>
                                {(model: FieldProps<number>) => {
                                  return <UnitInputV3 model={model} unit={{ options: [{ id: '', name: '%' }] }} />;
                                }}
                              </Field>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  );
                }}
              </FieldArray>
            </>
          ) : (
            <>
              <div className='flex self-start pt-2 text-zinc-500'>Composition</div>
              <button
                onClick={() => props.setEditMode(true)}
                className='flex flex-col gap-2 p-3 border border-white hover:border-zinc-200 hover:rounded-lg hover:shadow-md'
              >
                {props.item.materials?.map((material, i) => (
                  <div key={i}>{`${material?.subType?.name} (${material?.name}) - ${material?.compositionPercent}%`}</div>
                ))}
              </button>
              <div className='flex self-start pt-2'>
                <TagBadge state={props.item.qualifier} />
              </div>
            </>
          );
        }

        return undefined;
      })()}
    </>
  );
};
