import { Route, Routes, useNavigate, useParams } from 'react-router';
import { setSelectedWorkspaceId } from '../../../api';
import { AppRoutes } from '../index';
import { useEffect } from 'react';
import { useProfile } from '../../../hooks/useProfile';

export const Workspaces = () => (
  <Routes>
    <Route path=':workspaceId/*' element={<Workspace />} />
  </Routes>
);

const Workspace = () => {
  const profile = useProfile();
  const navigate = useNavigate();
  const { workspaceId } = useParams<{
    workspaceId: string;
  }>();

  useEffect(() => {
    const id = profile.workspaces.find(({ workspaceSid }) => workspaceSid === workspaceId)?.workspaceSid;
    if (id) {
      setSelectedWorkspaceId(id);
    } else {
      navigate(AppRoutes().forbidden);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workspaceId]);

  return (
    <Routes>
      <Route path='products/*' element={<Product />} />
    </Routes>
  );
};

const Product = () => {
  const navigate = useNavigate();

  const params = useParams<{
    workspaceId: string;
    ['*']: string;
  }>();

  useEffect(() => {
    navigate(`${AppRoutes().products}/${params['*']}`!);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params['*']]);

  return <></>;
};
