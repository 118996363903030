import { useProfile } from '../../hooks/useProfile';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { clearAuthentication } from '../../api';
import { useNavigate } from 'react-router';

export const Forbidden = () => {
  const profile = useProfile();
  const navigate = useNavigate();

  return (
    <div className='-my-10 h-full w-full flex justify-center items-center'>
      <div className='p-4 flex flex-col gap-y-4 text-zinc-900 items-center border rounded-2xl shadow text-center max-w-96'>
        <FontAwesomeIcon className='size-10 text-red-500' icon={regular('ban')} />
        <div className='text-lg font-semibold'>You do not have access to this product</div>
        <div>Request access to the administrator or switch to an account with access.</div>
        <div className='flex flex-col gap-y-1.5 items-center p-2.5'>
          <div className='text-xs'>You are logged into</div>
          <div className='flex gap-x-2 items-center'>
            {profile.selectedWorkspace.logoUrl ? (
              <img className='shrink-0 size-8 rounded-full overflow-hidden' src={profile.selectedWorkspace.logoUrl} alt='' />
            ) : (
              <div className='shrink-0 size-8 flex justify-center items-center bg-violet-200 rounded-full'>
                <FontAwesomeIcon icon={regular('building')} />
              </div>
            )}
            <div className='truncate'>{profile.selectedWorkspace.name}</div>
          </div>
        </div>
        <button
          onClick={() => {
            clearAuthentication();
            navigate('/');
          }}
          type='button'
          className='px-4 py-2 rounded-full bg-slate-200 text-violet-950 text-sm font-semibold'
        >
          Sign out
        </button>
      </div>
    </div>
  );
};
