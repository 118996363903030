import { Route, Routes, useNavigate } from 'react-router';
import { Main } from './Main';
import { AddCustomer } from './AddCustomer';
import { useApiQuery } from '../../../hooks/useApiQuery';
import { getCustomers } from '../../../api';
import { CustomerOverview } from './Overview';

export const Customers = () => {
  const { data, reload } = useApiQuery(getCustomers());
  const customers = data;
  const reloadCustomers = reload;
  const navigate = useNavigate();

  if (!customers) {
    return <></>;
  }

  return (
    <div className='-mt-10 mx-6'>
      <Routes>
        <Route
          path='new-customer'
          element={
            <AddCustomer
              onDone={() => {
                reloadCustomers();
                navigate('/customers');
              }}
            />
          }
        />
        <Route path=':customerId/*' element={<CustomerOverview data={data} onBack={() => navigate('/customers')} />} />
        <Route path='*' element={<Main data={customers} />} />
      </Routes>
    </div>
  );
};
